import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import { Caption, P2, Strong } from '../../../Typography';

import { getProductCare, getProductWarning } from '../ProductAccordion';
import { getProductFromContext, getIsLifestyleImageryUpdateRequiredFromContext } from '../../../../context/product';
import { getProductType, parseMetafields } from '../../../../utils/product';
import { ProductType } from '../../../../types/common';
import { AccordionList } from '../../../Accordion';
import { getPDPTrackingLocation, handleSupportLinkTracking } from '../../../../utils/analytics';
import { isHolidayHideawayProduct } from '../../../../utils/bfcm';

const SpecsWrapper = styled.div``;

const SingleSpecWrapper = styled.div`
  margin-bottom: 24px;
`;

const FaintCaption = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const SpecsList = styled.ul`
  list-style-type: disc;
`;

const SpecsListItem = styled.li`
  list-style-type: disc;
  margin-left: 30px;
`;

const ProductSpecsText = styled(P2)``;

const AccordionBodyHeading = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const SpecsToggle: React.FC = () => {
  const product = getProductFromContext();
  const isLifestyleImageryUpdateRequired = getIsLifestyleImageryUpdateRequiredFromContext();
  const metafields = parseMetafields(product.metafields);
  const productType = getProductType(product.productType);

  return productType === ProductType.Hoodie ? (
    <>
      <AccordionBodyHeading>Materials</AccordionBodyHeading>
      <AccordionList>
        <ProductSpecsText>55% cotton, 45% polyester.*</ProductSpecsText>{' '}
        <ProductSpecsText>*Exclusive of decorative details.</ProductSpecsText>
        <ProductSpecsText>
          <Strong>Our premium poly-cotton blend offers a warm, low maintenance and more durable Hoodie.</Strong>
        </ProductSpecsText>
      </AccordionList>
      <br />
      <AccordionBodyHeading>Care Instructions</AccordionBodyHeading>
      <AccordionList>
        <ProductSpecsText>Machine washable. Please follow care instructions on Hoodie label.</ProductSpecsText>
        <br />
        {isLifestyleImageryUpdateRequired && (
          <>
            <ProductSpecsText>Plushie not included with purchase of Hoodie.</ProductSpecsText>
            <br />
          </>
        )}
        <ProductSpecsText>
          Please contact{' '}
          <StyledLink.Primary
            href="https://support.makeship.com/hc/en-us/requests/new"
            onClick={() => {
              handleSupportLinkTracking('open_support_ticket', getPDPTrackingLocation(product), product);
            }}
          >
            Makeship Customer Support
          </StyledLink.Primary>{' '}
          with any questions or if you need assistance.
        </ProductSpecsText>
      </AccordionList>
    </>
  ) : (
    <SpecsWrapper>
      <SingleSpecWrapper>
        <FaintCaption>Dimensions</FaintCaption>
        <SpecsList>
          {getProductCare(metafields)[productType]?.dimensions?.map((dimension) => (
            <SpecsListItem key={dimension.name}>
              <P2>
                {dimension.name}: {metafields[dimension.key as keyof typeof metafields] || dimension.default}
                {dimension.unit}
              </P2>
            </SpecsListItem>
          ))}
          {getProductCare(metafields)[productType]?.disclaimer && (
            <>
              <FaintCaption>{getProductCare(metafields)[productType]?.disclaimer}</FaintCaption>
            </>
          )}
        </SpecsList>
      </SingleSpecWrapper>
      <SingleSpecWrapper>
        <FaintCaption>Materials</FaintCaption>
        <SpecsList>
          {getProductCare(metafields)[productType]?.material.map((material) => (
            <SpecsListItem key={material}>
              <P2>{material}</P2>
            </SpecsListItem>
          ))}
        </SpecsList>
      </SingleSpecWrapper>
      <SingleSpecWrapper>
        {getProductCare(metafields)[productType]?.careInstructions && (
          <>
            <FaintCaption>Care Instructions</FaintCaption>
            <P2>{getProductCare(metafields)[productType]?.careInstructions}</P2>
          </>
        )}
      </SingleSpecWrapper>
      <SingleSpecWrapper>{productType && <P2>{getProductWarning(productType)}</P2>}</SingleSpecWrapper>
      {/* GITD 2024 */}
      {isHolidayHideawayProduct(product.id) && (
        <SingleSpecWrapper>
          <P2>
            <strong>Santa Fiya GITD Plushie not included in purchase of Holiday Hideaway GITD Plushie.</strong>
          </P2>
        </SingleSpecWrapper>
      )}
    </SpecsWrapper>
  );
};

export default SpecsToggle;
