import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { S3Styles, S3 } from '../../../Typography';
import { isAfterBFCMWeekend, isGITDProduct } from '../../../../utils/bfcm';
import config from '../../../../../config.json';
import { getProductFromContext } from '../../../../context/product';
import { ProductStage } from '../../../../types/common';
import { getStage } from '../../../../utils/product';

const GITDFundedBarBannerWrapper = styled.div`
  border-radius: 8px;
  background: linear-gradient(267deg, #31068b 30.35%, #ea50ff 140.1%);
  display: flex;
  min-height: 94px;
  margin-bottom: 30px;
  align-items: center;
  padding: 8px;
`;

const GITDFundedBarBannerIconWrapper = styled.div`
  margin-left: 4px;
`;

const GITDFundedBarText = styled(S3)`
  font-weight: 400;
  margin-left: 22px;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.neutral1};
`;

const GITDFundedBarLink = styled.a`
  ${S3Styles};
  color: #61e2e1;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

type GITDPromoBannerProps = {
  isSoldOut: boolean;
};

const GITDPromoBanner: React.FC<GITDPromoBannerProps> = ({ isSoldOut }: GITDPromoBannerProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);

  const shouldDisplayGITDPromoBanner = () =>
    (stage === ProductStage.Live || stage === ProductStage.Draft) && isGITDProduct(product.tags) && !isSoldOut;

  return shouldDisplayGITDPromoBanner() ? (
    <GITDFundedBarBannerWrapper>
      <GITDFundedBarBannerIconWrapper>
        <Image src="/assets/bfcm/GITDFundedBarBannerIcon.png" width={68} height={58} layout="fixed" />
      </GITDFundedBarBannerIconWrapper>
      <GITDFundedBarText>
        {!isAfterBFCMWeekend(new Date()) ? (
          <>
            <strong>10% off all GITD</strong> Plush! Buy any 2 or more, and get an <strong>extra 5% off.</strong>
          </>
        ) : (
          <>
            <strong>10% off when you buy 2 GITD</strong> Plush! Or buy 3+ and <strong>get 15% off!</strong>
          </>
        )}{' '}
        <span>
          <GITDFundedBarLink
            href={config.routes.shopGITD}
            onClick={() => posthog.capture('GITD2023_funded_bar_banner_click')}
          >
            {!isAfterBFCMWeekend(new Date()) ? 'Shop Black Friday Deals' : 'Shop Cyber Month Deals'}
          </GITDFundedBarLink>
        </span>
      </GITDFundedBarText>
    </GITDFundedBarBannerWrapper>
  ) : (
    <></>
  );
};

export default GITDPromoBanner;
