import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { Button } from '@makeship/core';
import { ProductStage, ProductTag, ProductType, SortOrder } from '../../../../types/common';
import {
  getStage,
  parseMetafields,
  hasProductTag,
  updateFundedBar,
  isProductSoldOut,
  getProductType,
  containsProductTag,
} from '../../../../utils/product';
import {
  createEvergreenTags,
  createTags,
  getSubscribeButtonText,
  getSubscribeDescription,
  getSubscribeTitle,
  renderShippingDate,
} from '../commonFunctions';
import CompleteCollectionComponent from '../CompleteCollectionComponent';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import ProductDetails from '../ProductDetails';
import ProgressBarContainer from '../ProgressBarContainer';
import PromoBanner from '../PromoBanner';
import AddToCartContainer from '../AddToCartContainer';
import RefundBlock from '../RefundBlock';
import { getIsCompleteTheCollectionEligible } from '../../../../utils/collections';
import { getBadgesByExternalProductId } from '../../../../api/storefront/badge';
import { decryptShopifyId } from '../../../../utils/id';
import ProductHeader from '../ProductHeader';
import EvergreenBanner from '../EvergreenBanner';
import { trackSubscribeButtonClicked } from '../../../../utils/analytics';
import GiftingComponent from '../GiftingComponent';
import PDPInfoDrawer from '../PDPInfoDrawer';
import CreatorMessage from '../CreatorMessage';
import { removeFirstProductImage } from '../../../../utils/hoodies';
import LiveProductRedirect from '../LiveProductRedirect';
import LiveProductRedirectFloating from '../LiveProductRedirectFloating';
import { useStore } from '../../../../store';
import config from '../../../../../config.json';
import { calculateTotalInventorySold } from '../../../../utils/product';
import PastPetitionMessage from '../PastPetitionMessage';
import { S3 } from '../../../Typography';
import { VariantSelectionError } from '../../../../types/errors';
import { isHolidayHideawayProduct } from '../../../../utils/bfcm';
import GITDPromoBanner from '../PromoBanner/GITDPromoBanner';

const LazySubscribeModal = dynamic(() => import('../../../Modal/SubscribeModal'), { ssr: false });

const ProductInfoWrapper = styled.div`
  width: 100%;
  display: block;
`;

const ProductHeaderWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const SubscribePDPButton = styled(Button.Tertiary)`
  width: 100%;
  padding: 15px 24px;
`;

const ComingSoonSubscribePDPButton = styled(Button.Secondary)`
  width: 100%;
  padding: 15px 24px;
`;

const PinAddOnBanner = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid rgba(83, 73, 237, 0.33);
  padding: 12px 10px;
`;

const GiftIcon = styled(S3)`
  font-size: 35px;
  line-height: 60px;
  margin-right: 10px;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  min-width: 54px;
  min-height: 54px;
`;

type ProductInfoProps = {
  setProductImages: React.Dispatch<React.SetStateAction<Shopify.ImageEdge[]>>;
  selectedVariant?: Shopify.ProductVariant;
  setSelectedVariant: (variant: Shopify.ProductVariant | undefined) => void;
  creatorCollectionHandle: string | null;
  makeshiftCollectionTitle: string | null;
  productsBySameCreator: Shopify.ProductEdge[];
  productsInSameMakeshiftCollection: Shopify.ProductEdge[];
  giftProduct?: Shopify.Product | null;
  pinAddOnProduct?: Shopify.Product | null;
  topCampaigns: Shopify.ProductEdge[];
};

const ProductInfo: React.FC<ProductInfoProps> = ({
  setProductImages,
  selectedVariant,
  setSelectedVariant,
  creatorCollectionHandle,
  makeshiftCollectionTitle,
  productsBySameCreator,
  productsInSameMakeshiftCollection,
  giftProduct,
  pinAddOnProduct,
  topCampaigns,
}: ProductInfoProps) => {
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();

  const { moq, totalInventory, setTotalInventory, setMOQ } = productDetails;
  const moqNumber = Number(moq);
  const totalInventoryNumber = Number(totalInventory);

  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const productType = getProductType(product.productType);

  const [badges, setBadges] = useState<Badge[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHoodieVariants, setSelectedHoodieVariants] = useState<Shopify.ProductVariant[]>([]);

  const fundedBarRef = useRef<HTMLDivElement>(null);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);
  const isSoldOut = isProductSoldOut(product, totalInventoryNumber);
  const hasManyVariants = product.variants.edges.length > 1;
  const hasProductionDesign = product.variants.edges.length === 2;
  const isPetitionCampaign = hasProductTag(product.tags, ProductTag.SuccessfulPetition);

  const { state } = useStore();
  const completeTheCollectionRef = useRef<HTMLDivElement>(null);
  const redirectBlockRef = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState<VariantSelectionError[]>([]);

  useEffect(() => {
    const clearUpdateFundedBar = updateFundedBar(stage, product, totalInventoryNumber, setTotalInventory);

    // Reset hoodie variants when PDP changes
    setSelectedHoodieVariants([]);

    return () => {
      clearUpdateFundedBar();
    };
  }, [product]);

  useEffect(() => {
    const fetchBadges = async () => {
      try {
        // Determine the sort order based on the product stage
        const sortOrder = getStage(product.tags) === ProductStage.Live ? SortOrder.Descending : SortOrder.Ascending;

        // Fetch the initial badges
        const { data } = await getBadgesByExternalProductId({
          externalProductId: decryptShopifyId(product.id).toString(),
          direction: sortOrder,
          offset: '0',
          limit: '3',
        });

        // Update the state with the fetched badges
        setBadges(data);
      } catch (error) {
        // Handle errors (e.g., update the state, show a message to the user)
        console.error('Failed to fetch initial badges:', error);
      }
    };

    const fetchProductSalesQuantity = async () => {
      if (stage === ProductStage.Past && metafields.totalsold) {
        setTotalInventory(metafields.totalsold);
        return;
      }
      const shopifyProductInventory = Math.abs(product.totalInventory ?? 0);
      const totalInventory = await calculateTotalInventorySold(product);

      setTotalInventory(Number(totalInventory) ?? Number(shopifyProductInventory));
    };

    setBadges([]);
    setMOQ(Number(metafields.moq) || Number(config.defaultMOQ));
    fetchBadges();
    fetchProductSalesQuantity();

    if (productType !== ProductType.Hoodie) {
      setSelectedVariant(product.variants.edges[0].node);
    } else {
      setSelectedVariant(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (selectedVariant) {
      const filteredImages = product.images.edges.filter(({ node }) =>
        hasManyVariants
          ? node.altText?.includes(selectedVariant?.title) || !node.altText
          : node.altText === selectedVariant.image?.altText,
      );
      setProductImages(removeFirstProductImage(filteredImages, productType, stage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  return (
    <ProductInfoWrapper>
      {!isEvergreen && (
        <ProductHeaderWrapper data-testid="desktop-product-header">
          <ProductHeader selectedVariant={selectedVariant} />
        </ProductHeaderWrapper>
      )}
      <ProductDetails selectedVariant={selectedVariant} creatorCollectionHandle={creatorCollectionHandle} />
      {stage !== ProductStage.Demo && (
        <>
          {/* GITD 2024 holiday hideaway */}
          {!isEvergreen && !isHolidayHideawayProduct(product.id) && (
            <ProgressBarContainer fundedBarRef={fundedBarRef} />
          )}
          {/* GITD 2024 holiday hideaway */}
          {stage === ProductStage.Past &&
            metafields.delivery &&
            !isHolidayHideawayProduct(product.id) &&
            renderShippingDate(metafields.delivery, stage, false, product)}
          <PromoBanner />
          {/* GITD 2024 promo banner. Does not show for Adora Plushie */}
          {stage === ProductStage.Live &&
            containsProductTag(product.tags, 'gitd2024') &&
            !product.id.includes('8216118296732') && <GITDPromoBanner isSoldOut={isSoldOut} />}
          {stage === ProductStage.Failed && <RefundBlock />}
          {isEvergreen && <EvergreenBanner />}
          {((stage === ProductStage.Live && product.availableForSale) || stage === ProductStage.Draft) &&
            !isSoldOut && (
              <AddToCartContainer
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                completeTheCollectionRef={completeTheCollectionRef}
                selectedHoodieVariants={selectedHoodieVariants}
                setSelectedHoodieVariants={setSelectedHoodieVariants}
                errors={errors}
                setErrors={setErrors}
              />
            )}
          {/* GITD 2024 holiday hideaway */}
          {(stage === ProductStage.ComingSoon || isSoldOut) && !isHolidayHideawayProduct(product.id) && (
            <>
              <ComingSoonSubscribePDPButton
                data-testid="subscribe-pdp-button"
                onClick={() => {
                  setIsModalOpen(true);
                  trackSubscribeButtonClicked(product, state.user, getSubscribeButtonText(stage, isEvergreen));
                }}
              >
                {getSubscribeButtonText(stage, isEvergreen)}
              </ComingSoonSubscribePDPButton>
              {pinAddOnProduct && (
                <PinAddOnBanner>
                  <GiftIcon>🎁</GiftIcon>
                  <S3>
                    Stay tuned for an exclusive enamel pin from{' '}
                    {metafields.creator ? metafields.creator : 'the creator'} 👀
                  </S3>
                </PinAddOnBanner>
              )}
            </>
          )}
          {/* topCampaigns is only fetched when stage is Past or Failed. */}
          {(stage === ProductStage.Past || stage === ProductStage.Failed) && topCampaigns.length > 0 && (
            <>
              <LiveProductRedirectFloating
                metafields={metafields}
                productsBySameCreator={productsBySameCreator}
                productsInSameMakeshiftCollection={productsInSameMakeshiftCollection}
                product={product}
                makeshiftCollectionTitle={makeshiftCollectionTitle}
                topCampaigns={topCampaigns}
                redirectBlockRef={redirectBlockRef}
              />
              <LiveProductRedirect
                metafields={metafields}
                productsBySameCreator={productsBySameCreator}
                productsInSameMakeshiftCollection={productsInSameMakeshiftCollection}
                product={product}
                makeshiftCollectionTitle={makeshiftCollectionTitle}
                topCampaigns={topCampaigns}
                redirectBlockRef={redirectBlockRef}
              />
              {pinAddOnProduct && (
                <PinAddOnBanner>
                  <ImageWrapper>
                    <Image
                      src={pinAddOnProduct.images.edges[0].node.transformedSrc}
                      alt={pinAddOnProduct.title}
                      layout="fill"
                      objectFit="contain"
                      quality={90}
                      unoptimized
                    />
                  </ImageWrapper>
                  <S3>This Plushie was part of an exclusive offer with a matching enamel pin! ✨</S3>
                </PinAddOnBanner>
              )}
            </>
          )}
          {(stage === ProductStage.Live || stage === ProductStage.Draft) &&
            metafields.delivery &&
            !isLimited &&
            !isEvergreen &&
            renderShippingDate(metafields.delivery, stage, false, product)}
          {getIsCompleteTheCollectionEligible(
            productsBySameCreator,
            productsInSameMakeshiftCollection,
            hasProductionDesign,
            stage,
            isSoldOut,
            product,
            pinAddOnProduct,
          ) && (
            <CompleteCollectionComponent
              metafields={metafields}
              productsBySameCreator={productsBySameCreator}
              productsInSameMakeshiftCollection={productsInSameMakeshiftCollection}
              product={product}
              stage={stage}
              makeshiftCollectionTitle={makeshiftCollectionTitle}
              completeTheCollectionRef={completeTheCollectionRef}
              pinAddOnProduct={pinAddOnProduct}
              setSelectedVariant={setSelectedVariant}
              selectedHoodieVariants={selectedHoodieVariants}
              setSelectedHoodieVariants={setSelectedHoodieVariants}
              errors={errors}
              setErrors={setErrors}
            />
          )}
          {(stage === ProductStage.Live || stage === ProductStage.Draft) && giftProduct && (
            <GiftingComponent stage={stage} giftProduct={giftProduct} />
          )}
        </>
      )}
      <>
        {isPetitionCampaign && <PastPetitionMessage />}
        <CreatorMessage />
        {productsBySameCreator.length < 1 &&
          productsInSameMakeshiftCollection.length < 1 &&
          // GITD 2024 holiday hideaway
          (stage === ProductStage.Past || stage === ProductStage.Failed) &&
          !isHolidayHideawayProduct(product.id) && (
            <SubscribePDPButton
              data-testid="subscribe-pdp-button"
              onClick={() => {
                setIsModalOpen(true);
                trackSubscribeButtonClicked(product, state.user, getSubscribeButtonText(stage, isEvergreen));
              }}
            >
              {getSubscribeButtonText(stage, isEvergreen)}
            </SubscribePDPButton>
          )}
        {stage !== ProductStage.ComingSoon && <PDPInfoDrawer badges={badges} />}
      </>
      <LazySubscribeModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        tags={
          isEvergreen
            ? createEvergreenTags(product)
            : createTags(product, creatorCollectionHandle, totalInventoryNumber, moqNumber, isSoldOut)
        }
        description={getSubscribeDescription(stage, isEvergreen, product.title, metafields.creator)}
        title={getSubscribeTitle(stage, isEvergreen)}
      />
    </ProductInfoWrapper>
  );
};

export default ProductInfo;
