import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { Link as StyledLink, utils } from '@makeship/core';
import posthog from 'posthog-js';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import { Caption, H5, S2 } from '../../../Typography';
import Badge from '../../../Badge';
import {
  getEvergreenInventoryState,
  getProductIDFromShopifyGID,
  getProductType,
  getProductVariantsDetails,
  getStage,
  hasProductTag,
  isProductSoldOut,
  parseMetafields,
} from '../../../../utils/product';
import { getFormattedAmount } from '../../../../utils/accounting';
import { BadgeText, ProductStage, ProductTag, ProductType } from '../../../../types/common';

const ProductDetailsWrapper = styled.div<{ isEvergreen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 32px;
  align-items: center;

  ${({ isEvergreen }) => isEvergreen && `padding: 40px 32px;`}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 40px 16px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${({ isEvergreen }) => isEvergreen && `padding: 24px 32px;`}
  }
`;

const ProductTitle = styled(H5)`
  text-align: center;
`;

const CreatorName = styled(S2)`
  margin-top: 4px;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceRow = styled.div<{
  isDetailItem?: boolean;
  isEvergreen?: boolean;
}>`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin: ${({ isDetailItem }) => (isDetailItem ? '0' : '12px 0')};
  }

  ${({ isEvergreen }) => isEvergreen && `margin-top: 12px;`}
`;

const ProductPrice = styled(S2)<{ sale: boolean; isDetailItem?: boolean }>`
  ${({ sale }) =>
    sale &&
    `margin-right: 12px;
    text-decoration: line-through;
    text-decoration-thickness: 3px;
  `}
  color: ${({ sale, theme }) =>
    sale ? utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark) : theme.colors.neutral7};
  font-weight: ${({ isDetailItem }) => isDetailItem && '400'};
`;

const FromText = styled(S2)`
  margin-right: 5px;
`;

const ProductSalePrice = styled(S2)`
  color: ${({ theme }) => theme.colors.error};
  text-transform: uppercase;
`;

const SalePriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
`;

const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledBadge = styled(Badge)`
  position: inherit;
  background-color: ${({ theme }) => theme.colors.failedBadge};
  text-transform: uppercase;
  ${Caption} {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const CreatorNameLink = styled(StyledLink.Primary)``;

const CreatorLogoImageWrapper = styled.div`
  margin-bottom: 16px;
  cursor: pointer;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 0;
  }
`;

type ProductDetailsProps = {
  selectedVariant: Shopify.ProductVariant | undefined;
  creatorCollectionHandle: string | null;
};

const ProductDetails: React.FC<ProductDetailsProps> = ({
  selectedVariant,
  creatorCollectionHandle,
}: ProductDetailsProps) => {
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();
  const { totalInventory } = productDetails;
  const productType = getProductType(product.productType);
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);
  const isSoldOut = isProductSoldOut(product, Number(totalInventory));

  const renderCreatorLogo = () => {
    const isNinjaKiwiProduct = product.id === 'gid://shopify/Product/8181284503708';
    const isADateWithDeathProduct = product.id === 'gid://shopify/Product/8249756811420';
    const isHololiveProduct = hasProductTag(product.tags, ProductTag.Hololive);

    if (isNinjaKiwiProduct)
      return (
        <Link href="/shop/creator/ninjakiwi">
          <CreatorLogoImageWrapper>
            <Image src="/assets/ninjakiwi-logo.jpg" width={120} height={33} alt="Ninja Kiwi Logo" />
          </CreatorLogoImageWrapper>
        </Link>
      );

    if (isADateWithDeathProduct)
      return (
        <Link href="/shop/creator/adatewithdeath">
          <CreatorLogoImageWrapper>
            <Image src="/assets/two-and-a-half-logo.png" width={120} height={75} alt="A Date with Death Logo" />
          </CreatorLogoImageWrapper>
        </Link>
      );
    if (isHololiveProduct)
      return (
        <Link href="/shop/creator/hololive">
          <CreatorLogoImageWrapper>
            <Image src="/assets/collections/hololive_logo.png" width={120} height={33} alt="Hololive Logo" />
          </CreatorLogoImageWrapper>
        </Link>
      );
  };

  const renderProductPrice = (
    product: Shopify.Product,
    stage: ProductStage,
    selectedVariant?: Shopify.ProductVariant,
    isDetailItem?: boolean,
  ): React.ReactNode => {
    switch (stage) {
      case ProductStage.Live:
      case ProductStage.Draft: {
        const { hasManyVariants, lowestVariantPrice } = getProductVariantsDetails(product.variants.edges);

        if (selectedVariant?.compareAtPriceV2?.amount || lowestVariantPrice.compareAtPrice) {
          return (
            <ProductWrapper>
              <PriceRow isEvergreen={isEvergreen} isDetailItem={isDetailItem}>
                {hasManyVariants && !selectedVariant && productType !== ProductType.Hoodie && <FromText>From</FromText>}
                <SalePriceWrapper>
                  <ProductPrice data-testid="product-price" sale isDetailItem={isDetailItem}>
                    $
                    {getFormattedAmount(selectedVariant?.compareAtPriceV2?.amount || lowestVariantPrice.compareAtPrice)}
                  </ProductPrice>
                  <ProductSalePrice>
                    ${getFormattedAmount(selectedVariant?.priceV2.amount || lowestVariantPrice.price)}
                  </ProductSalePrice>
                </SalePriceWrapper>
              </PriceRow>
            </ProductWrapper>
          );
        }
        return (
          <PriceRow isEvergreen={isEvergreen} isDetailItem={isDetailItem}>
            {hasManyVariants && !selectedVariant && productType !== ProductType.Hoodie && <FromText>From</FromText>}
            <ProductPrice data-testid="product-price" sale={false} isDetailItem={isDetailItem}>
              ${getFormattedAmount(selectedVariant?.priceV2.amount || lowestVariantPrice.price)}
            </ProductPrice>
          </PriceRow>
        );
      }
      case ProductStage.ComingSoon:
        return (
          <ProductRow>
            <ProductPrice data-testid="product-price" sale={false}>
              Coming Soon
            </ProductPrice>
          </ProductRow>
        );
      case ProductStage.Failed:
        return (
          <ProductRow>
            <StyledBadge text={BadgeText.NotFunded} isActive />
          </ProductRow>
        );
    }

    return <></>;
  };

  return (
    <ProductDetailsWrapper isEvergreen={isEvergreen} data-testid="product-details-header">
      {renderCreatorLogo()}
      <ProductTitle>{product.title}</ProductTitle>
      {metafields.creator && (
        <CreatorName>
          <CreatorNameLink
            onClick={() =>
              posthog.capture('pdp_creatorLink_click', {
                productID: getProductIDFromShopifyGID(product.id),
                creatorName: metafields.creator,
                productStage: getStage(product.tags),
                linkHref: `/shop/creator/${creatorCollectionHandle}`,
                evergreen_inventory_state: getEvergreenInventoryState(product),
              })
            }
            href={`/shop/creator/${creatorCollectionHandle}`}
          >
            By: {metafields.creator}
          </CreatorNameLink>
        </CreatorName>
      )}
      {isEvergreen && !isSoldOut && renderProductPrice(product, stage, selectedVariant)}
    </ProductDetailsWrapper>
  );
};

export default ProductDetails;
