import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ProgressBar, utils } from '@makeship/core';
import posthog from 'posthog-js';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import {
  getProductIDFromShopifyGID,
  getProgressBarProps,
  getStage,
  hasProductTag,
  parseMetafields,
  shouldShowProgressBar,
} from '../../../../utils/product';
import { ProductStage, ProductTag } from '../../../../types/common';
import { Caption, P2, S2 } from '../../../Typography';
import { formatNumberStringWithComma } from '../../../../utils/accounting';
import { ReactComponent as Info } from '../../../../../public/assets/icons/info-icon-1.svg';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { useStore } from '../../../../store';

const ProgressBarWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const EndDate = styled(S2)`
  margin-bottom: 4px;
`;

const ProgressRow = styled.div<{ flexDirection?: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

const PastLimitedCampaignRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FundingStats = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral6};
  right: 0;
  bottom: 26px;
  width: 300px;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
`;

const TooltipText = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
  & > span {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const InfoIcon = styled(Info)`
  margin-left: 8px;
  vertical-align: text-top;
`;

const UnitsSoldText = styled(S2)``;

const PercentFundedText = styled(Caption)``;

type ProgressBarContainerProps = {
  fundedBarRef: React.RefObject<HTMLDivElement>;
};

const ProgressBarContainer: React.FC<ProgressBarContainerProps> = ({ fundedBarRef }: ProgressBarContainerProps) => {
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();
  const { state } = useStore();
  const { moq, totalInventory } = productDetails;
  const moqNumber = Number(moq);
  const totalInventoryNumber = Number(totalInventory);

  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const hideUnits = hasProductTag(product.tags, ProductTag.HideUnits);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const ref = useRef<HTMLDivElement>(null);

  const isOnProductCard = false;
  const { isGradient, percentage, funded, height } = getProgressBarProps(product, isOnProductCard, totalInventory);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (!!ref.current && !ref.current.contains(e.target as Node)) {
      setIsTooltipClicked(false);
    }
  };

  const handleTooltipClick = () => {
    setIsTooltipClicked(!isTooltipClicked);
    posthog.capture('pdp_fundedBarTooltip_click', {
      is_logged_in: isUserLoggedIn(state.user),
      product_id: getProductIDFromShopifyGID(product.id),
      product_stage: stage,
      product_title: product.title,
    });
  };

  const tooltip = (
    <Tooltip>
      <TooltipText>
        When a campaign is 100% funded, the product is guaranteed to go into production. You can still buy products that
        are funded for the duration of the campaign.
      </TooltipText>
    </Tooltip>
  );

  const [isTooltipClicked, setIsTooltipClicked] = useState(false);

  const getUnitsSoldText = (): React.ReactNode => {
    if (isLimited) {
      return `${formatNumberStringWithComma(moqNumber - totalInventoryNumber)} of ${formatNumberStringWithComma(
        moqNumber,
      )} sold`;
    }
    if (hideUnits && totalInventoryNumber >= moqNumber) {
      return `200+ sold`;
    }
    return `${formatNumberStringWithComma(totalInventoryNumber)} sold`;
  };

  const getPercentFundedComponent = (): React.ReactNode => {
    if (isLimited) {
      return <S2 data-testid="limited-funded-bar-text">Hurry! Before it&apos;s gone 🔥</S2>;
    }
    if (hideUnits && totalInventoryNumber >= moqNumber) {
      return (
        <>
          <PercentFundedText>100%+ Funded</PercentFundedText>
          <InfoIconWrapper ref={ref} onClick={handleTooltipClick}>
            <InfoIcon />
            {isTooltipClicked && tooltip}
          </InfoIconWrapper>
        </>
      );
    }
    return (
      <>
        <PercentFundedText>
          {formatNumberStringWithComma(Math.floor((totalInventoryNumber / moqNumber) * 100))}% Funded
        </PercentFundedText>
        <InfoIconWrapper ref={ref} onClick={handleTooltipClick}>
          <InfoIcon />
          {isTooltipClicked && tooltip}
        </InfoIconWrapper>
      </>
    );
  };

  const showLimitedCampaignRow = () => {
    if (stage === ProductStage.ComingSoon) {
      return <></>;
    }
    if (stage === ProductStage.Past || totalInventoryNumber <= 0) {
      return (
        <PastLimitedCampaignRow>
          <UnitsSoldText data-testid="units-sold-text">
            {moqNumber} of {moqNumber} sold
          </UnitsSoldText>
        </PastLimitedCampaignRow>
      );
    }
    return (
      <ProgressRow>
        <UnitsSoldText data-testid="units-sold-text">{getUnitsSoldText()}</UnitsSoldText>
        <FundingStats>{getPercentFundedComponent()}</FundingStats>
      </ProgressRow>
    );
  };

  return (
    <div ref={fundedBarRef}>
      <ProgressBarWrapper>
        {stage === ProductStage.ComingSoon && metafields.launch && (
          <EndDate>
            Launches:{' '}
            {utils.parseDate(metafields.launch).toDate().toLocaleString('default', { month: 'long', day: 'numeric' })}
          </EndDate>
        )}
        {(stage === ProductStage.Past || stage === ProductStage.Failed) && metafields.enddate && (
          <EndDate>Ended: {utils.getDate(metafields.enddate)}</EndDate>
        )}
        {shouldShowProgressBar(stage) && (
          <ProgressBar isGradient={isGradient} percentage={percentage} funded={funded} height={height} />
        )}
        {isLimited
          ? showLimitedCampaignRow()
          : stage !== ProductStage.Failed &&
            stage !== ProductStage.ComingSoon && (
              <ProgressRow>
                <UnitsSoldText>{getUnitsSoldText()}</UnitsSoldText>
                <FundingStats>{getPercentFundedComponent()}</FundingStats>
              </ProgressRow>
            )}
      </ProgressBarWrapper>
    </div>
  );
};

export default ProgressBarContainer;
