import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import { getStage, hasProductTag, parseMetafields } from '../../../../utils/product';
import { ProductTag, ProductStage } from '../../../../types/common';
import { S1, P2, Overline } from '../../../Typography';
import { renderLearnMoreShippingLink, renderShippingDate } from '../commonFunctions';
import InfographicWithEmojisComponent from './infographicEmojis';
import { isHolidayHideawayProduct } from '../../../../utils/bfcm';

const ProductInfographicWrapper = styled.div`
  display: flex;
`;

const InfographicLogoWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 8px 16px 0 0;
`;

const ProductInfographicDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductInfographicItem = styled.div<{ marginBottom?: string; height?: string | null }>`
  ${({ height }) => height && `height: ${height}px;`}
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
  &:first-child {
    margin-top: 4px;
  }
  &:last-child {
    margin-top: -24px;
  }

  & > ${Overline} {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

const HowItWorksShipping = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const ProductInfographic: React.FC = () => {
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();
  const { totalInventory, moq } = productDetails;
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);

  // GITD 2024
  return isHolidayHideawayProduct(product.id) ? (
    <></>
  ) : isLimited ? (
    <>
      {(stage === ProductStage.Live || stage === ProductStage.Draft) &&
        metafields.delivery &&
        !isLimited &&
        !isEvergreen &&
        renderShippingDate(metafields.delivery, stage, false, product)}
      <ProductInfographicWrapper>
        <InfographicLogoWrapper>
          <InfographicWithEmojisComponent
            emojis={[
              {
                emoji: '✅',
                containerSpace: 54,
                lineSpace: 100,
              },
              {
                emoji: '📦',
                containerSpace: 108,
                lineSpace: 100,
              },
              {
                emoji: '🏠',
                containerSpace: 0,
              },
            ]}
          />
        </InfographicLogoWrapper>
        <ProductInfographicDetails>
          <ProductInfographicItem marginBottom="46">
            <S1 data-testid="campaign-process-stage-text">Limited Edition Product Drop</S1>
          </ProductInfographicItem>
          <ProductInfographicItem marginBottom="48" height="104">
            <S1>Shipment</S1>
            <P2>Product will be shipped 2-4 days after purchase.</P2>
            <Overline>
              Delivery takes 7-15 business days.
              <br /> *Delivery to some areas may take <br />
              10-30 business days
            </Overline>
          </ProductInfographicItem>
          <ProductInfographicItem marginBottom="18">
            <S1>Delivered</S1>
          </ProductInfographicItem>
        </ProductInfographicDetails>
      </ProductInfographicWrapper>
    </>
  ) : (
    <>
      {(stage === ProductStage.Live || stage === ProductStage.Draft) &&
        metafields.delivery &&
        !isLimited &&
        !isEvergreen && (
          <HowItWorksShipping>
            {renderShippingDate(metafields.delivery, stage, false, product)}
            {renderLearnMoreShippingLink(product)}
          </HowItWorksShipping>
        )}
      <ProductInfographicWrapper>
        <InfographicLogoWrapper>
          <InfographicWithEmojisComponent
            emojis={[
              {
                emoji: '✅',
                containerSpace: 54,
                lineSpace: 100,
              },
              {
                emoji: '⚙️',
                containerSpace: 66,
                lineSpace: 100,
              },
              {
                emoji: '📦',
                containerSpace: 108,
                lineSpace: 100,
              },
              {
                emoji: '🏠',
                containerSpace: 0,
              },
            ]}
          />
        </InfographicLogoWrapper>
        <ProductInfographicDetails>
          <ProductInfographicItem marginBottom="46">
            <S1 data-testid="campaign-process-stage-text">
              {Number(totalInventory) >= Number(moq) ? 'Funded' : 'Crowdfunding'}
            </S1>
          </ProductInfographicItem>
          <ProductInfographicItem marginBottom="34" height="50">
            <S1>Production</S1>
            {metafields.enddate && <P2>Starts {utils.getDateNoYear(metafields.enddate)}</P2>}
          </ProductInfographicItem>
          <ProductInfographicItem marginBottom="48" height="104">
            <S1>Shipment</S1>
            {metafields.delivery && <P2>Estimated to ship {utils.getDateNoYear(metafields.delivery)}</P2>}
            <Overline>
              Delivery takes 7-15 business days.
              <br /> *Delivery to some areas may take <br />
              10-30 business days
            </Overline>
          </ProductInfographicItem>
          <ProductInfographicItem marginBottom="18">
            <S1>Delivered</S1>
          </ProductInfographicItem>
        </ProductInfographicDetails>
      </ProductInfographicWrapper>
    </>
  );
};

export default ProductInfographic;
