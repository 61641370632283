import { Link as StyledLink, utils } from '@makeship/core';
import React from 'react';
import styled from 'styled-components';
import posthog from 'posthog-js';
import {
  getProductIDFromShopifyGID,
  getProductType,
  getProductVariantsDetails,
  getStage,
  hasProductTag,
} from '../../../../utils/product';
import config from '../../../../../config.json';
import { BadgeText, ProductStage, ProductTag, ProductType } from '../../../../types/common';
import { getFormattedAmount } from '../../../../utils/accounting';
import Badge from '../../../Badge';
import { Caption, S2 } from '../../../Typography';
import { getProductFromContext } from '../../../../context/product';

type ProductCollectionType = {
  [x: string]: string;
};

type ConfigType = {
  productCollections: ProductCollectionType;
  collections: ProductCollectionType;
};

const { productCollections } = config as ConfigType;

const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ProductTypeText = styled(S2)`
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

const ProductCollectionLink = styled(StyledLink.Primary)``;

const FromText = styled(S2)`
  margin-right: 5px;
`;

const ProductPrice = styled(S2)<{ sale: boolean }>`
  ${({ sale }) =>
    sale &&
    `margin-right: 12px;
    text-decoration: line-through;
    text-decoration-thickness: 3px;
  `}
  color: ${({ sale, theme }) =>
    sale ? utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark) : theme.colors.neutral7};
`;

const ProductSalePrice = styled(S2)`
  color: ${({ theme }) => theme.colors.error};
  text-transform: uppercase;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SalePriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
`;

const StyledBadge = styled(Badge)`
  position: inherit;
  background-color: ${({ theme }) => theme.colors.failedBadge};
  text-transform: uppercase;
  ${Caption} {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const isHololive = (tags: string[]): boolean => hasProductTag(tags, ProductTag.Hololive);

const isGITD2024 = (tags: string[]): boolean => hasProductTag(tags, ProductTag.GITD);

const renderProductCollectionLink = (product: Shopify.Product): React.ReactNode => (
  <ProductCollectionLink
    data-testid="product-type-link"
    onClick={() =>
      posthog.capture('pdp_productTypeLink_click', {
        productID: getProductIDFromShopifyGID(product.id),
        productType: getProductType(product.productType),
        productStage: getStage(product.tags),
        linkHref: isHololive(product.tags)
          ? `/shop/hololive`
          : isGITD2024(product.tags)
          ? `/shop/gitd-2024`
          : `/shop/${productCollections[product.productType]}`,
      })
    }
    href={
      isHololive(product.tags)
        ? `/shop/hololive`
        : isGITD2024(product.tags)
        ? `/shop/gitd-2024`
        : `/shop/${productCollections[product.productType]}`
    }
  >
    {isHololive(product.tags) ? (
      <ProductTypeText>hololive production</ProductTypeText>
    ) : isGITD2024(product.tags) ? (
      <ProductTypeText>Black Friday/Cyber Month</ProductTypeText>
    ) : (
      renderProductType(product.productType)
    )}
  </ProductCollectionLink>
);
const renderProductType = (productType: string): React.ReactNode => {
  let productTypeText = productType;
  productType === ProductType.EnamelPin1Pack ||
  productType === ProductType.EnamelPin2Pack ||
  productType === ProductType.EnamelPin3Pack
    ? (productTypeText = 'Enamel Pins')
    : productType === ProductType.PlushKeychain
    ? (productTypeText = 'Keychain Plushies')
    : (productTypeText = getProductType(productTypeText));
  return <ProductTypeText>{productTypeText}</ProductTypeText>;
};

const renderProductPrice = (
  product: Shopify.Product,
  stage: ProductStage,
  selectedVariant?: Shopify.ProductVariant,
): React.ReactNode => {
  const productType = getProductType(product.productType);
  switch (stage) {
    case ProductStage.Live:
    case ProductStage.Draft: {
      const { hasManyVariants, lowestVariantPrice } = getProductVariantsDetails(product.variants.edges);

      if (selectedVariant?.compareAtPriceV2?.amount || lowestVariantPrice.compareAtPrice) {
        return (
          <ProductWrapper>
            <PriceRow>
              {hasManyVariants && !selectedVariant && productType !== ProductType.Hoodie && <FromText>From</FromText>}
              <SalePriceWrapper>
                <ProductPrice sale>
                  ${getFormattedAmount(selectedVariant?.compareAtPriceV2?.amount || lowestVariantPrice.compareAtPrice)}
                </ProductPrice>
                <ProductSalePrice>
                  ${getFormattedAmount(selectedVariant?.priceV2.amount || lowestVariantPrice.price)}
                </ProductSalePrice>
              </SalePriceWrapper>
            </PriceRow>
          </ProductWrapper>
        );
      }
      return (
        <PriceRow>
          {hasManyVariants && !selectedVariant && productType !== ProductType.Hoodie && <FromText>From</FromText>}
          <ProductPrice data-testid="product-price" sale={false}>
            ${getFormattedAmount(selectedVariant?.priceV2.amount || lowestVariantPrice.price)}
          </ProductPrice>
        </PriceRow>
      );
    }
    case ProductStage.ComingSoon:
      return (
        <ProductRow>
          <ProductPrice data-testid="product-comingsoon" sale={false}>
            Coming Soon
          </ProductPrice>
        </ProductRow>
      );
    case ProductStage.Past:
      return (
        <ProductRow>
          <Badge text={BadgeText.SoldOut} isActive />
        </ProductRow>
      );
    case ProductStage.Failed:
      return (
        <ProductRow>
          <StyledBadge text={BadgeText.NotFunded} isActive />
        </ProductRow>
      );
  }

  return <></>;
};

type ProductHeaderProps = {
  selectedVariant?: Shopify.ProductVariant;
};

const ProductHeader: React.FC<ProductHeaderProps> = ({ selectedVariant }: ProductHeaderProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);

  return (
    <>
      <ProductRow>
        {renderProductCollectionLink(product)}
        {renderProductPrice(product, stage, selectedVariant)}
      </ProductRow>
    </>
  );
};

export default ProductHeader;
