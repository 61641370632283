import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { S1, P2 } from '../../../Typography';

const RefundGuaranteedWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RefundContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const RefundBlock: React.FC = () => (
  <RefundGuaranteedWrapper>
    <LogoWrapper>
      <Image src="/assets/icons/refund-icon.svg" alt="Refund Icon" width={50} height={50} layout="fixed" />
    </LogoWrapper>
    <RefundContentWrapper>
      <S1>Our Campaign Guarantee</S1>
      <P2>
        This campaign did not reach its minimum funding goal. Please allow 4-7 business days for all orders to be
        refunded.
      </P2>
    </RefundContentWrapper>
  </RefundGuaranteedWrapper>
);

export default RefundBlock;
