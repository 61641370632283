import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { getProductFromContext } from '../../context/product';
import { ProductStage, ProductTag } from '../../types/common';
import { getStage, hasProductTag, isEligibleForTimer } from '../../utils/product';
import { S2, H5 } from '../Typography';
import CountdownTimer from '../CountdownTimer';
import { isHolidayHideawayProduct } from '../../utils/bfcm';

const ProductCountdownTimerWrapper = styled.div<{
  isLastDay?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 8px 0;
`;

const CountdownTitle = styled(S2)``;

const CountdownTimerVariantWrapper = styled.div``;

type ProductPageCountdownProps = {
  currentDate: string;
  totalInventory: number;
};

const renderCountdownTitle = (stage: string, isLastDay?: boolean, isLimited?: boolean): React.ReactNode => {
  if (stage === ProductStage.ComingSoon || stage === ProductStage.Draft) {
    return <>Campaign launching in...</>;
  }

  if (isLimited) {
    return;
  }

  if (isLastDay) {
    return <>Last chance before it&apos;s gone!</>;
  }
  return <>Available for a limited time only!</>;
};

const renderCountdownTimer = (stage: string, currentDate: string, productTags: string[]): React.ReactNode => {
  const isLastDay = utils.getDayDiffFrom(currentDate) === 0;
  const isLimited = productTags.includes(ProductTag.Limited);

  return (
    <CountdownTimerVariantWrapper>
      <ProductCountdownTimerWrapper isLastDay={isLastDay}>
        <CountdownTitle data-testid="product-countdown-title">
          {renderCountdownTitle(stage, isLastDay, isLimited)}
        </CountdownTitle>
        <CountdownTimer date={currentDate} />
      </ProductCountdownTimerWrapper>
    </CountdownTimerVariantWrapper>
  );
};

const ProductPageCountdown: React.FC<ProductPageCountdownProps> = ({
  currentDate,
  totalInventory,
}: ProductPageCountdownProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);

  return (
    <>
      {hasProductTag(product.tags, ProductTag.Limited) &&
        !isHolidayHideawayProduct(product.id) &&
        totalInventory > 0 &&
        stage !== ProductStage.ComingSoon && (
          <ProductCountdownTimerWrapper>
            <S2>Limited Edition!</S2>
            <H5>Only {totalInventory} left!</H5>
          </ProductCountdownTimerWrapper>
        )}
      {currentDate && isEligibleForTimer(product) && renderCountdownTimer(stage, currentDate, product.tags)}
    </>
  );
};

export default ProductPageCountdown;
